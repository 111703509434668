<template>
    <div class="reference-letter">
        <div v-if="!loading" class="content">
            <q-pageflow class="pageflow" :title="project.name" :back="`/projects/${projectId}`" paddingLeft="30px">
                <template v-slot:items>
                    <q-pageitem 
                        v-for="(item, index) in pageFlowItems" 
                        :key="item.label" 
                        class="pageitem" 
                        :class="{ active: activeStep === index, pointer: item.clickable }" 
                        :value="item.value" 
                        :style="`--item-width: ${90 / pageFlowItems.length}%`"
                        :type="item"
                        @click.native="navigateToStep(index)"
                    >{{ item.label }}</q-pageitem>
                </template>
            </q-pageflow>

            <div class="viewport">
                <letter-settings 
                    class="side-menu" 
                    :class="{ show: activeStep >= 2 }" 
                    :project="project"
                    :referenceLetter="referenceLetter"
                    :configurations="configurations"
                    :index="settingsStep"
                    @input="handleConfigurationUpdated"
                    @selectStyle="handleSelectStyle"
                    @selectAddress="handleSelectAddress"
                    @selectIntroTemplate="handleSelectIntroTemplate"
                    @selectClosingTemplate="handleSelectClosingTemplate"
                ></letter-settings>
                <div class="step-view" :class="{ 'has-side-menu': activeStep >= 2 }">
                    <div class="steps-container" :style="`--translate-x: ${translateX}%`">
                        <document-upload-step 
                            class="step upload"
                            :project="project"
                            :documents="documents"
                            @documentsUpdated="handleDocumentsUpdated"
                            @reloadReferences="reloadReferences"
                        ></document-upload-step>
                        <reference-overview-step 
                            class="step overview"
                            :project="project"
                            :documents="documents"
                            :referenceLetter="referenceLetter"
                            :key="reloadKey"
                            @projectUpdated="handleProjectUpdated"
                            @indicatorRemoved="handleIndicatorRemoved"
                            @indicatorsAdded="handleIndicatorsAdded"
                            @indicatorUpdated="handleIndicatorUpdated"
                            @saveIndicator="handleSaveIndicator"
                            @indicatorVisibilityToggled="handleIndicatorVisibilityToggled"
                            @labelVisibilityToggled="handleLabelVisibilityToggled"
                        ></reference-overview-step>
                        <reference-letter 
                            ref="referenceLetter"
                            class="step letter"
                            :referenceLetter="referenceLetter" 
                            :project="project"
                            :configurations="configurations"
                            :viewOnly="activeStep === 3"
                            :viewMode="viewMode"
                            :pages="pages"
                            @isMissingValues="handleMissingValuesUpdated"
                            @navigateToStep="navigateToStep"
                            @introTextUpdated="handleIntroTextUpdated"
                            @closingTextUpdated="handleClosingTextUpdated"
                            @viewModeUpdated="handleViewModeUpdated"
                            @orderChangeFinished="handleOrderChangeFinished"
                        ></reference-letter>
                    </div>
                </div>
            </div>

            <div class="bottom-right-button" :class="{ show: viewMode !== 'preview' || true }" >
                <q-tooltip class="tooltip" position="top" :class="{ show: disabledReason }">
                    <template #tooltip >
                        <span v-html="disabledReason"></span>
                    </template>
                    <div class="question-circle">
                        <q-icon type="QuestionCircle" width="24" height="24" color="red"></q-icon>
                    </div>
                </q-tooltip>
                <q-button
                    class="action-button"
                    :disabled="Boolean(disabledReason)"
                    :loading="downloadLoading"
                    @click="handleNextStep"
                >{{ buttonLabel }}</q-button>
                <jump-transition>
                    <q-label-switch
                        v-if="activeStep === 3"
                        v-model="selectedExportType"
                        :options="exportOptions"
                        size="small"
                    ></q-label-switch>
                </jump-transition>
            </div>

        </div>
        <div v-else class="center">
            <div class="loader"></div>
        </div>
    </div>
</template>

<script>
import { REFERENCE_LETTER, CONFIGURATIONS, GET_PROJECT_DOCUMENTS, ORGANISATION_SMALL } from '@/graphql/queries';
import { REFERENCE_LETTER_CONFIGURATION_UPDATED, UPDATE_INDICATOR } from '@/graphql/mutations';
import gql from 'graphql-tag';

import DocumentUploadStep from './DocumentUploadStep.vue';
import ReferenceOverviewStep from './ReferenceOverviewStep.vue';
import LetterSettings from './LetterSettings.vue';
import ReferenceLetter from './ReferenceLetter.vue';

import { isValidAnswer, getDate, extractError, parseSize, sortComponents, getReferenceletterExportName } from '@/assets/js/utils.js';
import { getReferenceLetterHtml } from '@/assets/js/downloadPdf.js';

import download from 'downloadjs';
import Axios from 'axios';

export default {
    name: 'reference-letter-2',
    components : {
        DocumentUploadStep,
        ReferenceOverviewStep,
        LetterSettings,
        ReferenceLetter
    },
    data() {
        return {
            projectId: this.$route.params.id,
            referenceLetterId: this.$route.params.referenceLetterId,
            referenceLetter: null,
            project: null,
            loading: true,
            activeStep: 0,
            highestVisitedStep: 0,
            settingsStep: 0,
            translateX: 0,
            viewMode: 'edit',
            isMissingValues: false,
            documents: [],
            pages: 0,
            steps: ['upload','fill_references','edit','send'],
            downloadExportUrl: process.env.DOWNLOAD_URL || 'http://localhost:5050/download',
            downloadLoading: false,
            finished: false,
            availableStepIndexes: [],
            selectedExportType: 'pdf',
            exportOptions: [
                {
                    label: 'PDF',
                    value: 'pdf'
                },
                {
                    label: 'DOCX',
                    value: 'docx'
                }
            ],
            reloadKey: 0,
        }
    },
    methods: {
        async getReferenceLetter() {
            try {
                const result = await this.$apollo.query({
                    query: REFERENCE_LETTER,
                    variables: {
                        id: this.referenceLetterId
                    },
                    fetchPolicy: 'no-cache'
                })
                let referenceLetter = result.data.referenceLetter;
                referenceLetter.introText = referenceLetter.introText || '';
                referenceLetter.closingText = referenceLetter.closingText || '';
                return referenceLetter
            } catch(error) {
                throw new Error(error)
            }
        },
        async getProjectWithReferences() {
            try {
                const result = await this.$apollo.query({
                    query: gql`
                    query Project($id: String!) {
                        project_access(id: $id) {
                            jwt
                            project {
                                id
                                name
                                status
                                contractorStatus
                                templates {
                                    id
                                    name
                                }
                                finishedDate
                                clientId
                                client {
                                    id
                                    name
                                    logo
                                    childs {
                                        id
                                        name
                                        address {
                                            line1
                                            line2
                                            city
                                            zip
                                        }
                                    }
                                }
                                contractorId
                                references {
                                    id
                                    name
                                    components {
                                        id
                                        display
                                        disabled
                                        indicator {
                                            id
                                            name
                                            type
                                            required
                                            masterId
                                            answerType
                                            displayType
                                            unit
                                            labelIds
                                            answerValues {
                                                value
                                                label
                                            }
                                            context {
                                                id
                                                name
                                                default
                                                order
                                            }
                                        }
                                        rsxMetadata {
                                            answerReferences {
                                                documentId
                                                documentName
                                                documentUserName
                                                specificationPosts {
                                                    specificationsPostNumber
                                                    unit
                                                    value
                                                    description
                                                }
                                            }
                                            hasBeenOverwritten
                                            indicatorMatchingType
                                        }
                                    }
                                    responses {
                                        values {
                                            componentId
                                            indicatorId
                                            value
                                        }
                                    }
                                    groups
                                    creationDate
                                }
                                referencesData
                            }
                        }
                    }
                `,
                    variables: {
                        id: this.projectId
                    },
                    fetchPolicy: 'no-cache'
                });
                this.setJwt(result.data.project_access.jwt);
                
                const project = this.setProject(result.data.project_access.project);

                return project
            } catch(error) {
                throw new Error(error)
            }
        },
        async getConfigurations() {
            try {
                const result = await this.$apollo.query({
                    query: CONFIGURATIONS,
                    fetchPolicy: 'no-cache'
                })
                return result.data.org_configurations
            } catch(error) {
                throw new Error(error)
            }
        },
        async getDocuments() {
            const projectJwtString = localStorage.getItem('projectJwt')
            const projectJwt = JSON.parse(projectJwtString) || {};

            const fields = ['documentId', 'name', 'size', 'documentUrl', 'uploadTimestamp', 'uploadedUserName'];

            try {
                const result = await this.$apollo.query({
                    query: GET_PROJECT_DOCUMENTS,
                    variables: {
                        projectId: this.projectId,
                        fields,
                        projectJwt: projectJwt.jwt
                    },
                    fetchPolicy: 'no-cache'
                })
                return result.data.documents_getProjectDocuments.map(data => {
                    const fileType = data.fields.name.split('.').pop();
                    const uploadDate = new Date(data.fields.uploadTimestamp).toLocaleString('nl-NL', { day: 'numeric', month: 'short', year: 'numeric' });

                    return {
                        ...data.fields,
                        id: data.fields.documentId,
                        documentName: data.fields.name,
                        uploadDate,
                        size: parseSize(data.fields.size || 0),
                        uploading: false,
                        fileType
                    }
                });
            } catch(error) {
                throw new Error(error)
            }
        },
        setProject(project) {
            const filteredComponents = project.references.components.filter(component => !component.disabled && component.indicator.type === 'reference');
            const sortedComponents = sortComponents(filteredComponents);
            project.references.components = sortedComponents;

            project.referencesId = project.references.id;

            return project
        },
        setJwt(jwt) {
            const projectJwt = {
                id: this.$route.params.id,
                jwt
            }
            localStorage.setItem('projectJwt', JSON.stringify(projectJwt))

            this.projectJwt = jwt;
        },
        _saveReferenceLetter: _.debounce(function() {
            this.saveReferenceLetter()
        }, 500),
        saveReferenceLetter(updatedLetter) {
            if(updatedLetter) this.referenceLetter = updatedLetter;

            if(this.highestVisitedStep >= 2 && this.disabledReason) this.highestVisitedStep = 2;
            
            const variables = {
                referenceLetterId: this.referenceLetter.id,
                showAllProjectReferences: this.referenceLetter.showAllProjectReferences,
                exportAsLetter: this.referenceLetter.exportAsLetter,
                styleId: this.referenceLetter.styleId,
                introText: this.referenceLetter.introText,
                closingText: this.referenceLetter.closingText,
                step: this.getLetterStep(),
                hiddenComponentIds: this.referenceLetter.hiddenComponentIds
            }
            if(this.referenceLetter.addressReceiver) variables['addressReceiver'] = {
                name: this.referenceLetter.addressReceiver.name,
                line1: this.referenceLetter.addressReceiver.line1,
                line2: this.referenceLetter.addressReceiver.line2
            }

            this.$apollo.mutate({
                mutation: REFERENCE_LETTER_CONFIGURATION_UPDATED,
                variables
            })
            .catch(error => {
                console.log(error);
                this.$store.commit('notify', { type: 'warning', message: 'Er is iets fout gegaan tijdens het opslaan' })
            })
        },
        handleDocumentsUpdated(documents) {
            this.documents = documents;
        },
        handleNextStep() {
            if(this.activeStep === 3) return this.handleDownload()

            const nextStep = this.activeStep + 1;
            if(!this.availableStepIndexes.includes(nextStep)) return

            this.activeStep = nextStep;
            this.updateHighestStep();
            this.navigateToStep(this.activeStep);
        },
        handleDownload() {
            if(this.selectedExportType === 'pdf') this.downloadReferenceLetterPDF()
            else this.handleDownloadReferenceLetterDOCX()
        },
        navigateToStep(index) {
            if(!this.availableStepIndexes.includes(index)) return
            if(index === 3 && this.disabledReason) return
            this.activeStep = index;
            const step = Math.min(2, this.activeStep);
            this.settingsStep = Math.max(0, this.activeStep - 2);
            this.translateX = -100 * step;
            this.updateHighestStep();
            const newLetterStep = this.getLetterStep();
            if(this.referenceLetter.step !== newLetterStep) {
                this.referenceLetter.step = newLetterStep;
                this.saveReferenceLetter();
            }
        },
        updateHighestStep() {
            if(this.highestVisitedStep < this.activeStep) this.highestVisitedStep = this.activeStep;
            if(this.activeStep > 1) this._setSpacing();
        },
        getLetterStep() {
            return this.steps[this.highestVisitedStep]
        },
        getLetterStepIndex() {
            return this.steps.indexOf(this.referenceLetter.step)
        },
        setProjectAbility() {
            const members = this.project.members || [];
            const user = this.$store.getters.getUser;
            const organisation = this.$store.getters.getCurrentOrganisation;
            const member = members.find(
                (member) => member.userId === user.id && member.organisationId === organisation.id
            );
            const isGeustOrganisation =
                this.project.client &&
                this.project.contractor &&
                organisation.id !== this.project.client.id &&
                organisation.id !== this.project.contractor.id;

            this.projectAbility.set(this.project.id, member, this.ability, isGeustOrganisation);
        },
        handleSelectStyle(styleId) {
            if(!styleId) return
            if(styleId === 'create-new') return this.handleRedirectAdmin('/settings/styles')

            this.referenceLetter.styleId = styleId;
            if(this.viewMode === 'preview') this._setSpacing();

            this.saveReferenceLetter();
        },
        handleSelectIntroTemplate(templateId) {
            if(!templateId) return this.letterIntroTextTemplateId = ''
            if(templateId === 'create-new') return this.handleRedirectAdmin('/settings/templates/introtext')

            const template = this.configurations.find(template => template.id === templateId);
            if(!template) return

            this.referenceLetter.introText = template.text;
            if(this.viewMode === 'preview') this._setSpacing();

            this.saveReferenceLetter();
        },
        handleSelectClosingTemplate(templateId) {
            if(!templateId) return this.letterClosingTextTemplateId = ''
            if(templateId === 'create-new') return this.handleRedirectAdmin('/settings/templates/closingtext')

            const template = this.configurations.find(template => template.id === templateId);
            if(!template) return

            this.referenceLetter.closingText = template.text;
            if(this.viewMode === 'preview') this._setSpacing();

            this.saveReferenceLetter();
        },
        handleRedirectAdmin(to) {
            if(!this.canManageConfigurations) return this.$store.commit('notify', { type: 'info', message: 'U heeft hier geen recht op. Vraag een beheerder.' })
            this.$router.push(to)
        },
        handleSelectAddress(branchId) {
            if(!branchId) return
            const branch = this.project.client.childs.find(organisation => organisation.id === branchId)
            if(!branch) return

            const zip = branch.address.zip.replace(/\s/g, '');

            this.referenceLetter.addressReceiver = {
                name: `${branch.name}`,
                line1: branch.address.line1,
                line2: `${zip} ${branch.address.city}`
            };
            if(this.viewMode === 'preview') this._setSpacing();

            this.saveReferenceLetter();
        },
        handleIntroTextUpdated(text) {
            this.referenceLetter.introText = text;
            this._saveReferenceLetter();
        },
        handleClosingTextUpdated(text) {
            this.referenceLetter.closingText = text;
            this._saveReferenceLetter();
        },
        setProjectComponents() {
            let projectComponents = this.project.references.components.filter(component => component.indicator.type === 'reference');
            if(this.project.clientStatus !== 'finished') projectComponents = projectComponents.filter(component => component.id !== 'component-invoiced-sum');
            
            const answeredComponents = this.project.referencesData;

            this.project.references.components = projectComponents.map((component, index) => {
                const answeredComponent = answeredComponents.find(answeredComponent => answeredComponent.componentId === component.id)
                let value = answeredComponent?.value;
                
                if(['default-context','crow-project-details'].includes(component.indicator.labelIds[0])) component.indicator.required = true;
                component.indicator.answer = value;
                component.hidden = this.referenceLetter.hiddenComponentIds.includes(component.id);

                if(component.indicator.answerType === 'organisation' && isValidAnswer(value)) {
                    component.indicator.answerValues = [
                        {
                            value,
                            label: 'laden...'
                        }
                    ]
                    if(component.indicator.name.toLowerCase() === 'branch') this.setBranchAnswerValue(value, index);
                    else this.setOrganisationAnswerValue(value, index);
                }

                return component
            })

            this.project.indicators = projectComponents
                .map((component) => {
                    return {
                        ...component.indicator,
                        componentId: component.id,
                        display: component.display,
                        disabled: component.disabled,
                        hidden: component.hidden,
                        rsxMetadata: component.rsxMetadata
                    };
                });
        },
        setOrganisationAnswerValue(organisationId, index) {
            this.$apollo.query({
                query: ORGANISATION_SMALL,
                variables: {
                    id: organisationId
                }
            })
            .then(result => {
                const label = result.data.organisation.name;
                const value = result.data.organisation.id;
                this.project.references.components[index].indicator.answerValues = [ { label, value } ];
            })
        },
        setBranchAnswerValue(organisationId, index) {
            this.$apollo.query({
                query: ORGANISATION_SMALL,
                variables: {
                    id: organisationId
                }
            })
            .then(result => {
                const label = result.data.organisation.address.city;
                const value = result.data.organisation.id;
                this.project.references.components[index].indicator.answerValues = [ { label, value } ];
            })
        },
        handleConfigurationUpdated() {
            this.saveReferenceLetter();
            if(this.viewMode === 'preview') this._setSpacing();
        },
        handleResize() {
            this._setSpacing();
        },
        handleMissingValuesUpdated(isMissingValues) {
            this.isMissingValues = isMissingValues;
        },
        _setSpacing: _.debounce(function() {
            this.setSpacing();
        }, 1000),
        async setSpacing() {
            await new Promise(r => setTimeout(r, 300));
            const overflowContainer = this.$refs.referenceLetter;
            const pageHeight = 1122.519685;
            const footerHeight = 58;
            const topPageOffset = 81 + 125;
            const showPreview = this.viewMode === 'preview' || this.activeStep === 3;
            const page = document.getElementById('page-preview')

            const groups = document.querySelectorAll('.spaced-group')
            for(let i = 0; i < groups.length; i++) {
                const group = groups[i];
                const spacedGroupMargin = group.classList[0] === 'indicator-group' ? 20 : 0;
                
                group.style.marginTop = '0px';
                if(!showPreview) continue
                
                const height = group.getBoundingClientRect().height + spacedGroupMargin;
                const scrollTop = overflowContainer.$el.scrollTop || 0;
                const top = group.getBoundingClientRect().y + scrollTop - topPageOffset - overflowContainer.$el.scrollTop;
                const bottom = top + height;
                
                const isInBreakingPoint = (bottom + footerHeight) % pageHeight < height;
                if(isInBreakingPoint) {
                    if(height < pageHeight) {
                        group.style.marginTop = `${(height + footerHeight + spacedGroupMargin) - ((bottom + footerHeight) % pageHeight)}px`
                        await new Promise(r => setTimeout(r, 1))
                        continue
                    }

                    const items = document.querySelectorAll('.spaced-item, .text-block');
                    for(let i = 0; i < items.length; i++) {
                        const item = items[i];
                        item.style.marginTop = '0px';
                        await new Promise(r => setTimeout(r, 1))
                        const itemHeight = item.getBoundingClientRect().height;
                        const itemTop = item.getBoundingClientRect().y - topPageOffset - overflowContainer.$el.scrollTop;
                        const itemBottom = itemTop + itemHeight;
                        const isInBreakingPoint = (itemBottom + footerHeight) % pageHeight < (itemHeight + footerHeight);
                        if(isInBreakingPoint) {
                            item.style.marginTop = `${footerHeight + spacedGroupMargin}px`
                            await new Promise(r => setTimeout(r, 1))
                        }
                    }
                }
            }

            if(page && showPreview) this.pages = Math.ceil((page.getBoundingClientRect().height - 44) / pageHeight);
            else this.pages = 0;
        },
        handleIndicatorUpdated({ value, indicator }) {
            console.log('handle updated')
            const referenceDataIndex = this.project.referencesData.findIndex(reference => reference.componentId === indicator.componentId)
            if(referenceDataIndex === -1) {
                const newReferenceData = {
                    componentId: indicator.componentId,
                    indicatorId: indicator.id,
                    labelId: indicator.context.id,
                    value: value.value
                }
                this.project.referencesData.push(newReferenceData);
            }
            if(referenceDataIndex !== -1) this.project.referencesData[referenceDataIndex].value = value.value;

            const componentIndex = this.project.references.components.findIndex(component => component.id === indicator.componentId);
            indicator.answer = value.value;
            this.project.references.components[componentIndex].indicator = indicator;

            const responseValueIndex = this.project.references.responses[0].values.findIndex(responseValue => responseValue.componentId === value.componentId);
            if(responseValueIndex !== -1) this.project.references.responses[0].values[responseValueIndex] = value;
            else this.project.references.responses[0].values.push(value);
            
            this.setAvailableStepIndexes();
        },
        setIndicatorVisibility(indicator, hide) {
            const { componentId } = indicator;
            const componentIndex = this.referenceLetter.hiddenComponentIds.indexOf(componentId);
            const isHidden = componentIndex !== -1;

            if(hide && !isHidden) {
                this.referenceLetter.hiddenComponentIds.push(componentId);
            }
            else if(!hide && componentIndex !== -1) {
                this.referenceLetter.hiddenComponentIds.splice(componentIndex, 1);
            }
        },
        handleIndicatorVisibilityToggled(indicator) {
            const isHidden = this.referenceLetter.hiddenComponentIds.includes(indicator.componentId);
            this.setIndicatorVisibility(indicator, !isHidden);

            this.saveReferenceLetter();
            this.setProjectComponents();
            this.setAvailableStepIndexes();
        },
        handleLabelVisibilityToggled(label) {
            const labelIndicators = this.project.indicators.filter(indicator => indicator.context.id === label.id);
            const hiddenLabelIndicators = labelIndicators.filter(indicator => indicator.hidden);

            const labelIsHidden = labelIndicators.length === hiddenLabelIndicators.length;

            labelIndicators.forEach(indicator => this.setIndicatorVisibility(indicator, !labelIsHidden));

            this.saveReferenceLetter();
            this.setProjectComponents();
            this.setAvailableStepIndexes();
        },
        handleSaveIndicator(indicator) {
            const mappedComponents = this.project.references.components.map(component => {
                return {
                    id: component.id,
                    indicatorId: component.indicator.id,
                    enabled: component.enabled,
                    type: component.type,
                    required: component.indicator.required,
                    mandatory: component.indicator.mandatory,
                    description: component.description,
                    items: component.items,
                    styling: component.styling
                }
            })

            this.$apollo.mutate({
                mutation: UPDATE_FORM,
                variables: {
                    id: form.id,
                    components: mappedComponents
                }
            })
            .then(result => {
                this.project.references
            })
        },
        handleIndicatorRemoved(indicator) {
            const componentIndex = this.project.references.components.findIndex(component => component.id === indicator.componentId);
            this.project.references.components[componentIndex].disabled = true;
            this.setProjectComponents();
        },
        handleIndicatorsAdded(project) {
            this.project.references.components = project.references.components;
            this.setProjectComponents();
        },
        handleProjectUpdated(project) {
            this.project = project;
            this.setProjectComponents();
        },
        handleViewModeUpdated(viewMode) {
            this.viewMode = viewMode;
            this.setSpacing();
        },
        async downloadReferenceLetterPDF() {
            let footer = `<div style="font-size:10px; margin-left:74px; opacity: 0.7;">${this.project.name}</div>`
            let header = `<div></div>`

            let html = await getReferenceLetterHtml()

            this.downloadLoading = true;

            const headers = {
                responseType: 'blob',
                headers: {
                    'application-context': 'qfact',
                    Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                }
            };

            Axios
                .post(`${this.downloadExportUrl}/reference_letter/export/pdf`, { id: this.referenceLetterId }, headers)
                .then((response) => {
                    const content = response.headers['content-type'];
                    const exportName = getReferenceletterExportName(this.project.name);
                    download(response.data, exportName, content);
                    this.downloadLoading = false;
                })
                .catch((error) => {
                    this.$store.commit('notify', extractError(error));
                    this.downloadLoading = false;
                });
        },
        handleDownloadReferenceLetterDOCX() {
            this.downloadLoading = true;
            this.$refs.referenceLetter.handleOrderChanged();
        },
        handleOrderChangeFinished() {
            if(this.downloadLoading) this.downloadReferenceLetterDOCX();
        },
        async downloadReferenceLetterDOCX() {
            const headers = {
                responseType: 'blob',
                headers: {
                    'application-context': 'qfact',
                    Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                }
            };

            Axios
                .post(`${this.downloadExportUrl}/reference_letter/export/docx`, { id: this.referenceLetterId }, headers)
                .then((response) => {
                    const content = response.headers['content-type'];
                    const creationDate = new Date(this.project.references.creationDate).toLocaleString('NL-nl', { day: '2-digit', month: '2-digit', year: 'numeric' })
                    const fileName = `${this.project.name}_referentie_${creationDate}.docx`;
                    download(response.data, fileName, content);
                    this.downloadLoading = false;
                })
                .catch((error) => {
                    this.$store.commit('notify', extractError(error));
                    this.downloadLoading = false;
                });
        },
        setAvailableStepIndexes() {
            if(this.finished) this.availableStepIndexes = [2, 3];
            else if(!this.allRequiredIndicatorsFilled) this.availableStepIndexes = [0, 1];
            else this.availableStepIndexes = [0, 1, 2, 3];
        },
        reloadReferences() {
            this.getProjectWithReferences()
                .then(project => {
                    this.project = project;
                    this.setProjectComponents();
                    this.reloadKey++;
                })
        }
    },
    computed: {
        disabledReason: function() {
            if(this.activeStep === 0) return ''

            if(this.activeStep === 1 && !this.allRequiredIndicatorsFilled) return 'Alle verplichte kenmerken moeten ingevuld zijn'

            if(this.activeStep === 2 && !this.hasRequiredFilled && this.referenceLetter.exportAsLetter) return 'De referentiebrief is nog niet helemaal ingevuld'

            if(this.activeStep === 2 && this.isMissingValues && this.referenceLetter.exportAsLetter) return `
                Van niet alle in de brief gebruikte tokens zijn de kenmerken<br>
                in het project ingevuld, vul de rood gemarkeerde kenmerken<br>
                in voordat je de brief kan exporteren
            `

            return ''
        },
        allRequiredIndicatorsFilled: function() {
            const hiddenComponentIds = this.referenceLetter?.hiddenComponentIds || [];
            const requiredComponents = this.project.references.components.filter(component => component.indicator.required && !hiddenComponentIds.includes(component.id) && !component.display);
            return !requiredComponents.some(component => !isValidAnswer(component.indicator.answer))
        },
        hasRequiredFilled: function() {
            let requiredValues = [
                this.referenceLetter.styleId,
                this.referenceLetter.addressReceiver,
                this.referenceLetter.introText,
                this.referenceLetter.closingText
            ]
            if(!this.referenceLetter.exportAsLetter) requiredValues = [];

            return requiredValues.filter(value => Boolean(value)).length === requiredValues.length;
        },
        pageFlowItems: function() {
            const readyToSend = this.hasRequiredFilled && !this.disabledReason;
            const hasFilledAllReferences = this.project.references.components.filter(component => 
                !['default-context','crow-project-details'].includes(component.indicator.context.id) &&
                !isValidAnswer(component.indicator.answer)
            ).length === 0;

            const uploadStatus = this.highestVisitedStep === 0 ? this.documents.length > 0 ? 'succes' : 'not_done' : this.highestVisitedStep > 0  ? 'succes' : 'not_done';
            const fillStatus = this.highestVisitedStep === 1 ? hasFilledAllReferences ? 'succes' : 'not_done' : this.highestVisitedStep > 1  ? 'succes' : 'not_done';
            const configurationStatus = this.highestVisitedStep === 2 ? readyToSend ? 'succes' : 'not_done' : this.highestVisitedStep > 2 ? 'succes' : 'not_done';
            const sendStatus = this.highestVisitedStep === 3 ? this.highestVisitedStep === 3 && configurationStatus === 'succes' ? 'succes' : 'not_done' : this.highestVisitedStep > 3 ? 'succes' : 'not_done';
            
            return [
                {
                    label: 'Bestanden uploaden',
                    status: uploadStatus,
                    value: '',
                    clickable: true
                },
                {
                    label: 'Invullen en aanvullen',
                    status: fillStatus,
                    value: '',
                    clickable: true
                },
                {
                    label: 'Referentiebrief opstellen',
                    status: configurationStatus,
                    value: '',
                    clickable: true
                },
                {
                    label: 'Versturen',
                    status: sendStatus,
                    value: '',
                    clickable: configurationStatus === 'succes'
                }
            ]
        },
        buttonLabel: function() {
            if(this.activeStep === 0) return 'Naar invullen kenmerken'
            if(this.activeStep === 1) return 'Naar brief opstellen en controleren'
            if(this.activeStep === 2) return 'Naar bijlages en versturen'
            else return 'Exporteren'
        },
        canManageConfigurations: function() {
            return this.$store.getters.getOrganisationRole !== 'user-default'
        }
    },
    async created() {
        try {
            const result = await Promise.all([
                this.getReferenceLetter(),
                this.getProjectWithReferences(),
                this.getConfigurations(),
                this.getDocuments()
            ])
            if(!result) return this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout tijdens het ophalen van de referentiebrief' });
            this.loading = false;
            this.referenceLetter = result[0];
            this.project = result[1];
            this.configurations = result[2];
            this.documents = result[3];
    
            this.setProjectComponents();
            this.setAvailableStepIndexes();
            this.setProjectAbility();

            if(this.project.contractorStatus === 'finished') {
                this.finished = true;
                this.setAvailableStepIndexes();
                this.activeStep = 3;
                this.navigateToStep(this.activeStep);
                this.updateHighestStep();
            }
        } catch (error) {
            this.$store.commit('notify', { type: 'warning', message: error.message });
            this.$router.push('/projects/' + this.projectId)
        }


        const activeStep = this.getLetterStepIndex();
        if(activeStep !== -1) this.navigateToStep(activeStep);

        window.addEventListener('resize', this.handleResize())
    },
    beforeDestroy() {
        this.projectAbility.remove();
        window.removeEventListener('resize', this.handleResize());
    }
}
</script>

<style lang="scss" scoped>
@import '@/components/qds/assets/style/_variables.scss';

::-webkit-scrollbar {
    width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: $color-white;
    border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $color-grey-5;
    border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: $color-grey-7;
}

.pageitem {
    width: var(--item-width);

    &.active {
        width: calc(var(--item-width) + 10%);
    }
    &.pointer {
        cursor: pointer;
    }
}

.reference-letter {
    .content {
        --font-size: 14px;
        --height: 1122.519685px;

        .pageflow {
        }

        .viewport {
            display: flex;
            max-height: calc(100vh - 81px - 124px);
            max-width: 1500px;
            margin-inline: auto;
            overflow: hidden;

            .side-menu {
                position: absolute;
                left: 0;
                translate: -25vw 0;
                transition: translate .4s ease;
                overflow-y: auto;
                z-index: 1;

                &.show {
                    translate: 0 0;
                    transition: translate .3s ease .1s;
                }
            }

            .step-view {
                width: 100%;

                .steps-container {
                    display: flex;
                    translate: var(--translate-x) 0;
                    transition: translate .4s ease;
    
                    .step {
                        min-height: calc(100vh - 124px - 81px);
                        max-height: calc(100vh - 124px - 81px);
                        max-width: 100%;
                        min-width: 100%;
                    }

                    .letter {
                        overflow-y: auto;
                    }
                }
            }

        }

        .bottom-right-button {
            position: fixed;
            right: 100px;
            top: calc(100vh - 70px);
            display: flex;
            align-items: center;
            gap: 12px;
            opacity: 0;
            pointer-events: none;
            transition: .2s ease;

            &.show,
            .show {
                opacity: 1 !important;
                pointer-events: all !important;
            }

            .tooltip {
                opacity: 0;
                pointer-events: none;
                transition: .2s ease;
            }

            .question-circle {
                background: white;
                border-radius: 50%;
            }

            .action-button {

            }
        }
    }
}

.center {
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    
    .loader {
        $loader-color: #d1d5da;
        $loader-size: 100px;
        $loader-height: 14px;
        $loader-border-size: 5px;
        $loader-gap: 20px;
        $loader-animation-duration: 700ms;
        @import '@/components/qds/assets/loaders/loaders.scss';
        @include loader02;
    }
}

</style>