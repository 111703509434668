<template>
    <div class="detail-page-padding" style="max-width: 900px;">
        <div class="space-between">
            <div class="settings-back">
                <arrow-button color="#aeb5bc" direction="left" @click="$router.go(-1)"
                    >Beheer</arrow-button
                >
            </div>
        </div>
        <div class="mb-l">
            <div class="settings-intro">
                <div class="space-between vertical-center mb-m">
                    <h1>Single Sign-On configuratie</h1>
                    <div>
                        <q-tag :variation="ssoStatusColor">{{ ssoEnabledTag }}</q-tag>
                    </div>

                </div>
            </div>
        </div>

        <div class="q-card pb-xs mb-xl">
            <div class="account-intro mb-s">
                <h3>{{ssoTitle}}</h3>
            </div>

            <q-section hPadding="40" vPadding="13" class="row mt-s">
                <div class="col-xs-4 label">
                    <p>Stap 1: Kies een slug</p>
                </div>
                <div
                    :class="[
                        editing.includes('slug') ? 'fixed-width' : 'fixed-width',
                        editing.includes('slug') ? 'col-xs-5' : 'col-xs-7',
                    ]"
                >
                <p style="color: grey; z-index: 1;">{{ getBaseUrl('/sso/', true) }}</p>
                    <q-input labelPosition="inside"
                        v-if="editing.includes('slug')"
                        size="small"
                        v-model="slug"
                        @input="checkSlug"
                        :state="slugState"
                        :errorMessage="slugErrorMessage"
                        variation="blank"
                        class="no-padding input-margin"
                        :placeholder="slugPlaceholder"
                        
                    ></q-input>
                    <div v-else>
                        <p>{{ ssoSettings.slug !== '' ? ssoSettings.slug : '...' }}</p>
                    </div>
                </div>
                <div :class="['end', editing.includes('slug') ? 'col-xs-3' : 'col-xs-1']">
                    <div>
                        <div v-if="editing.includes('slug')">
                            <q-button variation="blank" size="xsmall" @click="toggle('slug')" class="cancel-button">Annuleren</q-button>
                            <q-button
                                variation="ghost"
                                size="xsmall"
                                @click="updateSso({slug})"
                                >Opslaan</q-button
                            >
                        </div>
                        <q-button v-else variation="light" size="xsmall" @click="toggle('slug')"
                            >Wijzigen</q-button
                        >
                    </div>
                </div>
            </q-section>
            
            <q-section hPadding="40" vPadding="13" borderTop class="row">
                <div class="col-xs-4 label">
                    <p>Stap 2: SSO inschakelen</p>
                </div>
                <div :class="['col-xs-7', 'fixed-width']">
                    <div class="row">
                        <p>{{ ssoSettings.enabled ? 'Ingeschakeld' : 'Uitgeschakeld' }}</p>
                    </div>
                </div>
                <div class="col-xs-1 end">
                    <q-switch v-model="ssoSettings.enabled" @input="updateSso({enabled: ssoSettings.enabled})"></q-switch>
                </div>
            </q-section>
            
            <q-section hPadding="40" vPadding="13" borderTop class="row">
                <div class="col-xs-4 label">
                    <p>Forceer inloggen met SSO</p>
                </div>
                <div :class="['col-xs-7', 'fixed-width']">
                    <div class="row">
                        <p>{{ ssoSettings.ssoRequired ? 'Gebruikers kunnen alleen middels SSO inloggen' : 'Gebruikers kunnen inloggen via de loginpagina of SSO' }}</p>
                    </div>
                </div>
                <div class="col-xs-1 end">
                    <q-switch v-model="ssoSettings.ssoRequired" @input="updateSso({ssoRequired: ssoSettings.ssoRequired})"></q-switch>
                </div>
            </q-section>

            <q-section hPadding="40" vPadding="13" borderTop class="row mt-s">
                <div class="col-xs-4 label">
                    <div class="vertical-center">
                    <p>Aangepast domein</p>
                    <q-tooltip position="top">
                                <template v-slot:tooltip>
                                    <p>Maak een DNS-record (CNAME) aan voor het ingestelde domein en richt deze naar <strong>{{ssoCustomDomain}}</strong></p> 
                                </template>
                                <div class="question-circle">
                                    <q-icon type="QuestionCircle"></q-icon>
                                </div>
                            </q-tooltip>

                    </div>
                </div>
                <div
                    :class="[
                        editing.includes('domain') ? 'fixed-width' : 'fixed-width',
                        editing.includes('domain') ? 'col-xs-5' : 'col-xs-7',
                    ]"
                >
                    <q-input labelPosition="inside"
                        v-if="editing.includes('domain')"
                        size="small"
                        v-model="domain"
                        @input="checkDomain"
                        :state="domainState"
                        :errorMessage="domainErrorMessage"
                        variation="blank"
                        class="no-padding input-margin"
                        placeholder="Custom domein e.g. sso.voorbeeld.com"
                        
                    ></q-input>
                    <div v-else>
                        <p>{{ ssoSettings.customDomain }}</p>
                    </div>
                </div>
                <div :class="['end', editing.includes('domain') ? 'col-xs-3' : 'col-xs-1']">
                    <div>
                        <div v-if="editing.includes('domain')">
                            <q-button variation="blank" size="xsmall" @click="toggle('domain')" class="cancel-button">Annuleren</q-button>
                            <q-button
                                variation="ghost"
                                size="xsmall"
                                @click="updateSso({domain})"
                                >Opslaan</q-button
                            >
                        </div>
                        <q-button v-else variation="light" size="xsmall" @click="toggle('domain')"
                            >Wijzigen</q-button
                        >
                    </div>
                </div>
            </q-section>

            <q-section hPadding="40" vPadding="13" borderTop class="row mt-s">
                <div class="col-xs-4 label">
                    <div class="vertical-center">
                    <p>Standaardrol</p>
                    <q-tooltip position="top">
                                <template v-slot:tooltip>
                                    <p>Standaardrol voor nieuwe gebruikers</p> 
                                </template>
                                <div class="question-circle">
                                    <q-icon type="QuestionCircle"></q-icon>
                                </div>
                            </q-tooltip>

                    </div>
                </div>
                <div
                    :class="[
                        editing.includes('defaultRole') ? 'fixed-width' : 'fixed-width',
                        editing.includes('defaultRole') ? 'col-xs-5' : 'col-xs-7',
                    ]">
                        <q-dropdown
                            v-if="editing.includes('defaultRole')"
                            v-model="ssoSettings.defaultRole"
                            :options="roles"
                            size="small"
                            autocomplete>
                        </q-dropdown>

                    <div v-else>
                        <p>{{ defaultRole }}</p>
                    </div>
                </div>
                <div :class="['end', editing.includes('defaultRole') ? 'col-xs-3' : 'col-xs-1']">
                    <div>
                        <div v-if="editing.includes('defaultRole')">
                            <q-button variation="blank" size="xsmall" @click="toggle('defaultRole')" class="cancel-button">Annuleren</q-button>
                            <q-button
                                variation="ghost"
                                size="xsmall"
                                @click="updateSso({ defaultRole: ssoSettings.defaultRole })"
                                >Opslaan</q-button
                            >
                        </div>
                        <q-button v-else variation="light" size="xsmall" @click="toggle('defaultRole')"
                            >Wijzigen</q-button
                        >
                    </div>
                </div>
            </q-section>
            
        </div>
        
        
        <div class="q-card pb-xs mb-xl" :style="opacity">
            <div class="account-intro mb-s">
                <h3>Basic SAML configuratie</h3>
            </div>

            <q-section hPadding="40" vPadding="13" class="row mt-s">
                <div class="col-xs-4 label">
                    <p>Entity ID</p>
                </div>
                <div class="fixed-width col-xs-7">
                    <p>{{ getEntityId }}</p>
                </div>
                <div :class="['end']">
                    <div>
                        
                        <q-button variation="light" size="xsmall" @click="copy(getEntityId, 'Entity ID gekopieerd.')"
                            >Kopieren</q-button
                        >
                    </div>
                </div>
            </q-section>
            
            <q-section hPadding="40" vPadding="13" borderTop class="row">
                <div class="col-xs-4 label">
                    <p>Sign on URL</p>
                </div>
                <div :class="['col-xs-7', 'fixed-width']">
                    <div class="row">
                        <p>{{ getBaseUrl(`/sso/${ssoSettings.slug}`) }}</p>
                    </div>
                </div>
                <div class="end">
                    <q-button variation="light" size="xsmall" @click="copy(getBaseUrl(`/sso/${ssoSettings.slug}`), 'Sign on URL gekopieerd.')"
                            >Kopieren</q-button
                        >
                    </div>
            </q-section>
            
            <q-section hPadding="40" vPadding="13" borderTop class="row">
                <div class="col-xs-4 label">
                    <p>Callback URL</p>
                </div>
                <div :class="['col-xs-7', 'fixed-width']">
                    <div class="row">
                        <p>{{ ssoSettings.callbackUrl }}</p>
                    </div>
                </div>
                <div class="end">
                    <q-button variation="light" size="xsmall" @click="copy(ssoSettings.callbackUrl, 'Callback URL gekopieerd.')"
                            >Kopieren</q-button
                        >
                    </div>
            </q-section>
            
            <q-section hPadding="40" vPadding="13" borderTop class="row">
                <div class="col-xs-4 label">
                    <p>Metadata URL</p>
                </div>
                <div :class="['col-xs-7', 'fixed-width']">
                    <div class="row">
                        <p>{{ ssoSettings.metadataUrl }}</p>
                    </div>
                </div>
                <div class="end">
                    <q-button variation="light" size="xsmall" @click="copy(ssoSettings.metadataUrl, 'Metadata URL gekopieerd.')"
                            >Kopieren</q-button
                        >
                    </div>
            </q-section>
            
            <q-section hPadding="40" vPadding="13" borderTop class="row">
                <div class="col-xs-4 label">
                    <p>Public Key</p>
                </div>
                <div :class="['col-xs-7', 'fixed-width']">
                    <div class="row">
                        <p>••••••••••••••••••••••••••••••••••••••••</p>
                    </div>
                </div>
                <div class="end">
                    <q-button variation="light" size="xsmall" @click="copy(ssoSettings.publicKey, 'Public key gekopieerd.')"
                            >Kopieren</q-button
                        >
                    </div>
            </q-section>
            
        </div>

        <div class="q-card pb-xs mb-xl" :style="opacity">
            <div class="account-intro mb-s">
                <h3>Identity Provider Configuratie</h3>
            </div>

            <q-section hPadding="40" vPadding="13" class="row mt-s">
                <div class="col-xs-4 label">
                    <p>Login URL</p>
                </div>
                <div
                    :class="[
                        editing.includes('loginUrl') ? 'fixed-width' : 'fixed-width',
                        editing.includes('loginUrl') ? 'col-xs-5' : 'col-xs-7',
                    ]"
                >
                    <q-input labelPosition="inside"
                        v-if="editing.includes('loginUrl')"
                        size="small"
                        v-model="ssoSettings.loginUrl"
                        :state="loginUrlState"
                        :errorMessage="loginUrlErrorMessage"
                        variation="blank"
                        class="no-padding input-margin"
                        :placeholder="loginUrlPlaceholder"
                        
                    ></q-input>
                    <div v-else>
                        <p>{{ ssoSettings.loginUrl ? ssoSettings.loginUrl : '-' }}</p>
                    </div>
                </div>
                <div :class="['end', editing.includes('loginUrl') ? 'col-xs-3' : 'col-xs-1']">
                    <div>
                        <div v-if="editing.includes('loginUrl')">
                            <q-button variation="blank" size="xsmall" @click="toggle('loginUrl')" class="cancel-button">Annuleren</q-button>
                            <q-button
                                variation="ghost"
                                size="xsmall"
                                @click="updateSso({loginUrl: ssoSettings.loginUrl})"
                                >Opslaan</q-button
                            >
                        </div>
                        <q-button v-else variation="light" size="xsmall" @click="toggle('loginUrl')"
                            >Wijzigen</q-button
                        >
                    </div>
                </div>
            </q-section>

            <q-section hPadding="40" vPadding="13" borderTop class="row mt-s">
                <div class="col-xs-4 label">
                    <p>Logout URL</p>
                </div>
                <div
                    :class="[
                        editing.includes('logoutUrl') ? 'fixed-width' : 'fixed-width',
                        editing.includes('logoutUrl') ? 'col-xs-5' : 'col-xs-7',
                    ]"
                >
                    <q-input labelPosition="inside"
                        v-if="editing.includes('logoutUrl')"
                        size="small"
                        v-model="ssoSettings.logoutUrl"
                        :state="logoutUrlState"
                        :errorMessage="logoutUrlErrorMessage"
                        variation="blank"
                        class="no-padding input-margin"
                        :placeholder="logoutUrlPlaceholder"
                        
                    ></q-input>
                    <div v-else>
                        <p>{{ ssoSettings.logoutUrl ? ssoSettings.logoutUrl : '-' }}</p>
                    </div>
                </div>
                <div :class="['end', editing.includes('logoutUrl') ? 'col-xs-3' : 'col-xs-1']">
                    <div>
                        <div v-if="editing.includes('logoutUrl')">
                            <q-button variation="blank" size="xsmall" @click="toggle('logoutUrl')" class="cancel-button">Annuleren</q-button>
                            <q-button
                                variation="ghost"
                                size="xsmall"
                                @click="updateSso({logoutUrl: ssoSettings.logoutUrl})"
                                >Opslaan</q-button
                            >
                        </div>
                        <q-button v-else variation="light" size="xsmall" @click="toggle('logoutUrl')"
                            >Wijzigen</q-button
                        >
                    </div>
                </div>
            </q-section>
            

            <q-section hPadding="40" vPadding="13" borderTop class="row mt-s">
                <div class="col-xs-4 label">
                    <p>Certificate</p>
                </div>
                <div
                    :class="[
                        editing.includes('idpCertificate') ? 'fixed-width' : 'fixed-width',
                        editing.includes('idpCertificate') ? 'col-xs-5' : 'col-xs-7',
                    ]"
                >
                    <q-input labelPosition="inside"
                        v-if="editing.includes('idpCertificate')"
                        size="small"
                        v-model="ssoSettings.idpCertificate"
                        :state="idpCertificateState"
                        :errorMessage="idpCertificateErrorMessage"
                        variation="blank"
                        class="no-padding input-margin"
                        placeholder="Geef een certificaat op in pem formaat"
                        
                    ></q-input>
                    <div v-else>
                        <p>••••••••••••••••••••••••••••••••••••••••</p>
                    </div>
                </div>
                <div :class="['end', editing.includes('idpCertificate') ? 'col-xs-3' : 'col-xs-1']">
                    <div>
                        <div v-if="editing.includes('idpCertificate')">
                            <q-button variation="blank" size="xsmall" @click="toggle('idpCertificate')" class="cancel-button">Annuleren</q-button>
                            <q-button
                                variation="ghost"
                                size="xsmall"
                                @click="updateSso({idpCertificate: ssoSettings.idpCertificate})"
                                >Opslaan</q-button
                            >
                        </div>
                        <q-button v-else variation="light" size="xsmall" @click="toggle('idpCertificate')"
                            >Wijzigen</q-button
                        >
                    </div>
                </div>
            </q-section>
            
            
        </div>
        

        
    </div>
</template>

<script>

import ArrowButton from '@/components/ArrowButton';

import { ORG_UPDATE_SSO } from '@/graphql/mutations';
import { ORG_SETTINGS, ORGANISATIONS_JUST_ID_NAME, ROLES } from '@/graphql/queries'


export default {
    components: {
        ArrowButton
    },
    data() {
        return {
            ssoSettings: {
                enabled: false
            },
            ssoEnabled: false,
            slug: '',
            domain: '',
            ssoCustomDomain: process.env.CUSTOM_SSO_DOMAIN,
            slugState: null,
            slugErrorMessage: '',
            domainState: null,
            domainErrorMessage: '',
            loginUrlState: null,
            loginUrlErrorMessage: '',
            logoutUrlState: null,
            logoutUrlErrorMessage: '',
            idpCertificateState: null,
            idpCertificateErrorMessage: '',
            editing: [],
            roles: []
        }
    },
    methods: {
        toggle(type) {
            if (this.editing.includes(type)) this.editing = this.editing.filter(item => item !== type)
            else this.editing.push(type);
        },
        copy(text, notifyMessage) {
            const el = document.createElement('textarea');
            el.value = text;
            el.setAttribute('readonly', '');
            el.style.position = 'absolute';
            el.style.left = '-9999px';
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);

            this.$store.commit('notify', {type: 'info', message: notifyMessage ? notifyMessage : 'Waarde gekopieerd.'});
        },
        back() {
            this.$router.push('/projects/' + this.$route.params.id)
        },
        getBaseUrl(path = '', removeHttp = false) {
            // Removing http / https
            const baseUrl = removeHttp ? window.location.origin.replace(/(^\w+:|^)\/\//, '') : window.location.origin;
            return `${baseUrl}${path}`;
        },
        updateSso(ssoSettings) {
            this.editing = []

            const { slug, domain, enabled, ssoRequired, loginUrl, logoutUrl, defaultRole, idpCertificate } = ssoSettings

            if (this.validateSsoInput({...ssoSettings, slug: this.slug, domain: this.domain})) {
                this.$apollo
                    .mutate({
                        mutation: ORG_UPDATE_SSO,
                        variables: { organisationId: this.$store.getters.getCurrentOrganisation.id, slug: this.slug, customDomain: this.domain, enabled, ssoRequired, loginUrl, logoutUrl, defaultRole, idpCertificate },
                    })
                    .then((response) => {
                        this.ssoSettings = {...this.ssoSettings, ...response.data.org_updateDetails.settings.sso }
                    })
                    .catch((err) => {
                        this.$store.commit('notify', extractError(err));
                    });

            }
        },
        getSsoSettings() {
            const organisationId = this.$store.getters.getCurrentOrganisation.id;

            this.$apollo
                    .query({
                        query: ORG_SETTINGS,
                        variables: {
                                organisationId
                        },
                    })
                    .then((response) => {
                        const { sso } = response.data.organisation.settings;
                        this.ssoSettings = {...sso};
                        this.slug = this.ssoSettings.slug;
                        this.domain = this.ssoSettings.customDomain
                    })
                    .catch((err) => {
                        this.$store.commit('notify', extractError(err));
                    });
        },
        getRoles() {
            this.$apollo
                .query({
                    query: ROLES,
                    fetchPolicy:'no-cache'
                })
                .then(res => {
                    this.roles = res.data.roles.map(r => {
                        return {
                            value: r.id,
                            label: this.$t(r.name)
                        }
                    })
                })
                .catch((err) => {
                    this.rolesLoading = false;
                    this.$store.commit('notify', extractError(err));
                });

        },
        validateSsoInput({slug}) {
            if (slug === '') {
                this.$store.commit('notify', {type:'danger', message: 'slug cannot be empty'});
                return false
            }
            return true;

        },
        checkSlug: _.debounce(function () {
            if (this.slug === '') {
                this.slugState = null;
                return;
            }

            
            this.$apollo
                .mutate({
                    mutation: ORGANISATIONS_JUST_ID_NAME,
                    variables: { where: { settings_sso_slug: this.slug } },
                })
                .then((response) => {
                    this.slugState = response.data.organisations.length > 0
                        ? 'error'
                        : 'success';
                    if (response.data.organisations.length > 0) {
                        this.slugErrorMessage = 'Deze slug is al in gebruik';
                        return false;
                    }
                    return true;
                })
                .catch((err) => {
                    this.$store.commit('notify', extractError(err));
                });
            
        }, 1000),
        checkDomain: _.debounce(function () {
            if (this.domain === '') {
                this.domainState = null;
                return;
            }

            const domainValid = this.domain.match(/^([a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+)$/g)
            console.log(domainValid);
            if (!domainValid || domainValid.length === 0) {
                this.domainState = 'error';
                this.domainErrorMessage = 'Dit is geen geldig domein';
                return;
            }
            
            this.$apollo
                .mutate({
                    mutation: ORGANISATIONS_JUST_ID_NAME,
                    variables: { where: { settings_sso_customDomain: this.domain } },
                })
                .then((response) => {
                    this.domainState = response.data.organisations.length > 0
                        ? 'error'
                        : 'success';
                    if (response.data.organisations.length > 0) {
                        this.domainErrorMessage = 'Dit domein is al in gebruik';
                        return false;
                    }
                    return true;
                })
                .catch((err) => {
                    this.$store.commit('notify', extractError(err));
                });
            
        }, 1000)
    },
    computed: {
        opacity() {
            return this.ssoSettings.enabled ? {} : { opacity: '0.2' };
        },
        ssoTitle() {
            return this.ssoSettings.enabled ? 'Wilt u SSO uitschakelen?' : 'Wilt u SSO inschakelen?'
        },
        ssoEnabledTag() {
            return this.ssoSettings.enabled ? 'Ingeschakeld' : 'Uitgeschakeld'
        },
        ssoStatusColor() {
            return this.ssoSettings.enabled ? 'success' : 'default'
        },
        slugPlaceholder() {
            const orgName = this.$store.getters.getCurrentOrganisation.name;
            const slug = orgName.split(' ').join('-').toLowerCase();
            return slug
        },
        loginUrlPlaceholder() {
            return 'https://sso.login.url'
        },
        getEntityId() {
            return process.env.API_GATEWAY_URI
        },
        defaultRole() {
            if (!this.ssoSettings  || !this.ssoSettings.defaultRole) return '';

            const role = this.roles.find(role => role.value === this.ssoSettings.defaultRole);

            if (!role) return '';

            return role.label;
        }
    },
    created() {
        this.getSsoSettings()
        this.getRoles();
    }
}

</script>

<style lang="scss" scoped>
@import '../../../components/qds/assets/style/_variables.scss';
@import '../../../components/qds/assets/style/fonts/fonts.css';


span {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
}

.divider {
    margin-left: -3px;
}


.panel {
    display: flex;
    // align-items: center;
    flex-direction: column;
    width: 100%;
    // padding-bottom: 10px;
    margin-bottom: 64px;
    padding: 40px 0px 20px 0px;

    .title-area {
        padding: 0 40px;
        margin-bottom: 16px;
    }

    .row {

        height: 50px;

        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 40px;

        &:not(:last-child) {
            border-bottom: 1px solid #DEE2E6;
        }


        .name {
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            width: 200px;

        }

        .content {
            font-size: 13px;
            line-height: 22px;
            width: 400px;
        }

        .action {
            width: 60px
        }
    }
}


.head {
    margin-bottom: 48px;
    display: flex;
    justify-content: space-between;

    .left {
        display: flex;
        align-items: center;

        .status {
            margin-left: 10px;
        }

        h2 {
            font-weight: 500;
            font-size: 23px;
            line-height: 23px;
        }
    }
}

.no-padding {
    padding: 0 !important;
}

.account-intro {
    padding: 40px 40px 0px 40px;
}

.account-intro > p {
    margin-top: 15px;
}

.question-circle {
    margin-left: 8px;
}

.row > .label > p, .row > .label > div > p {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
}

.fixed-width p {
    max-width: 400px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.fixed-width {
    padding-left: 40px;
    height: 28px;
    display: flex;
    align-items: center;
}

// .fixed-width > div {
//     display: flex;
//     align-items: center;
// }

.end {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.input-margin {
    margin: -1px 0px 0px -7px;
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 150ms;
}

.cancel-button {
    margin-right: 8px;
}

* {
    font-family: Gotham 
}

</style>