import { render, staticRenderFns } from "./ProjectReferenceLetter.vue?vue&type=template&id=374dd3e6&scoped=true"
import script from "./ProjectReferenceLetter.vue?vue&type=script&lang=js"
export * from "./ProjectReferenceLetter.vue?vue&type=script&lang=js"
import style0 from "./ProjectReferenceLetter.vue?vue&type=style&index=0&id=374dd3e6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "374dd3e6",
  null
  
)

export default component.exports