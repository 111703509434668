<template>
    <div class="page-wrapper">
        <div class="dashboard page-padding">
            <div class="top">
                <h2>{{ getDaypart() }} {{ user.firstName }}!</h2>
                <q-button
                    v-can="'Project__create'"
                    v-if="canCreateNewProject"
                    :iconStyle="iconStyle"
                    iconSize="22"
                    icon="helmet"
                    @click="createProject"
                    >Nieuw project</q-button
                >
            </div>

            <div class="body" v-if="eventsLoading || events.length > 0">
                <div class="right">
                    <div class="options">
                        <span :class="option === 'forms' ? 'active' : ''" @click="option = 'forms'">Formulieren</span>
                        <span :class="option === 'projects' ? 'active' : ''" @click="option = 'projects'">Projecten</span>
                    </div>
                    <p>Meest recente wijzigingen</p>
                    <div class="content">
                        <ul class="timeline">
                            <li v-for="(event, key) in events" :key="key" @click="select(event)">
                                <div class="start">
                                    <q-tooltip
                                        v-if="event.triggerTitle && event.avatar && event.avatarFallback"
                                        position="top"
                                    >
                                        <template v-slot:tooltip>
                                            {{ event.triggerTitle }}
                                        </template>
                                        <q-avatar :img="event.avatar" size="small">{{ event.avatarFallback }}</q-avatar>
                                    </q-tooltip>
                                    <q-avatar
                                        v-else-if="event.avatar && event.avatarFallback"
                                        :img="event.avatar"
                                        size="small"
                                        >{{ event.avatarFallback }}</q-avatar
                                    >
                                    <q-tag :variation="event.tagVariation" size="small">{{ $t(event.tagText) }}</q-tag>
                                    <span class="small">{{ event.date }}</span>
                                    <span class="bold big">{{ event.text }}</span>
                                </div>
                                <div class="end on-hover">
                                    <q-button variation="light" size="xsmall">Bekijk</q-button>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div v-else>
                <q-zerostate
                    class="zerostate"
                    img="/statics/img/project_gradient.svg"
                    title="Projecten"
                    :description="zerostateDescription"
                >
                    <q-button v-if="canCreateNewProject" v-can="'Project__create'" @click="createProject"
                        >Nieuw project maken</q-button
                    >
                </q-zerostate>
            </div>

        </div>
        
        <working-animation></working-animation>
    </div>
</template>

<script>
import { GET_PROJECT_EVENTS, GET_FORM_EVENTS, GET_INDICATORS } from '../../graphql/queries';
import { extractError, getDate, userInitials, canCreateNewProject } from '../../assets/js/utils';

import WorkingAnimation from '@/components/WorkingAnimation.vue';

export default {
    name: 'Dashboard',
    components: {
        WorkingAnimation
    },
    data() {
        return {
            eventsLoading: true,
            user: this.$store.getters.getUser,
            option: 'projects',
            events: [],
            zerostateDescription:
                this.organisationType !== 'client'
                    ? 'Je bent nog niet uitgenodigd om aan een project deel te nemen.'
                    : this.ability.get().can('create', 'Project')
                    ? 'Je hebt nog geen project aangemaakt, of je bent nog niet uitgenodigd om aan een project deel te nemen. Ga vandaag aan de slag met je eerste project!'
                    : 'Er zijn nog geen project waar u lid van bent. Vraag een projectbeheerder om uitgenodigd te worden voor een project.',
            iconStyle: {
                marginLeft: '-10px',
                marginTop: '-2px'
            },
            organisationColor: '#EBCD5C'
        };
    },
    methods: {
        select(event) {
            this.$router.push(event.to);
        },
        getInitials(user) {
            return userInitials(user.firstName, user.lastName);
        },
        getEvents() {
            this.eventsLoading = true;
            if (this.option === 'projects')
                this.$apollo
                    .query({
                        query: GET_PROJECT_EVENTS,
                        fetchPolicy: 'no-cache'
                    })
                    .then(result => {
                        this.events = this.setProjectEvents(result.data.projectActivityFeed);
                        this.eventsLoading = false;
                    })
                    .catch(error => {
                        this.$store.commit('notify', extractError(error));
                        this.eventsLoading = false;
                    });
            else if (this.option === 'forms')
                this.$apollo
                    .query({
                        query: GET_FORM_EVENTS,
                        fetchPolicy: 'no-cache'
                    })
                    .then(result => {
                        this.events = this.setFormEvents(result.data.formActivityFeed);
                        this.eventsLoading = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$store.commit('notify', extractError(error));
                        this.eventsLoading = false;
                    });
        },
        setFormEvents(events) {
            const tagVariationMap = {
                created: 'success',
                form_ready: 'success',
                form_completed: 'success',
                form_disagree: 'warning'
            };

            return events.map(event => {
                return {
                    tagVariation: tagVariationMap[event.eventType] || 'default',
                    tagText: event.object.name,
                    date: getDate(event.timestamp),
                    text: `${event.object.projectName} - ${this.$t(event.object.name)}`,
                    to: '/forms/' + event.object.id
                };
            });
        },
        setProjectEvents(events) {
            const tagVariationMap = {
                created: 'success',
                finished: 'success',
                crow_project_ready: 'success',
                contractor_set: 'success',
                form_added: 'success',
                member_added: 'success'
            };

            return events.map(event => {
                const hasCausationUser =
                    event.metadata &&
                    event.metadata.causation &&
                    event.metadata.causation.user &&
                    event.metadata.causation.user.avatar;
                return {
                    tagVariation: tagVariationMap[event.eventType] || 'default',
                    tagText: event.eventType,
                    date: getDate(event.timestamp),
                    text: this.$t(event.object.name),
                    to: '/projects/' + event.object.id,
                    avatarFallback: hasCausationUser ? this.getInitials(event.metadata.causation.user) : '',
                    triggerTitle: hasCausationUser
                        ? `${event.metadata.causation.user.firstName} ${event.metadata.causation.user.lastName}`
                        : null,
                    avatar: hasCausationUser ? event.metadata.causation.user.avatar : null
                };
            });
        },
        getDaypart() {
            const hour = new Date().getHours();

            if (hour < 12) return 'Goedemorgen';
            else if (hour < 18) return 'Goedemiddag';
            else return 'Goedenavond';
        },
        createProject() {
            this.$router.push('/projects/create');
        }
    },
    computed: {
        organisationType: function() {
            return this.$store.getters.getOrganisationType;
        },
        canCreateNewProject: function() {
            return canCreateNewProject()
        }
    },
    watch: {
        option: function() {
            this.getEvents();
        }
    },
    created() {
        this.getEvents();
    },
};
</script>

<style scoped lang="scss">
@import '@/components/qds/assets/style/style.scss';

.dashboard {
    overflow-x: hidden;
}

.big {
    font-size: 14px;
}

.small {
    font-size: 12px;
}

.bold {
    font-weight: 500;
}

.options {
    margin-bottom: 30px;

    span {
        font-style: normal;
        font-weight: 500;
        font-size: 23px;
        line-height: 23px;
        color: #1d2443;
        opacity: 0.5;
        cursor: pointer;

        &.active {
            opacity: 1;
        }

        &:first-child {
            margin-right: 10px;
        }
    }
}

.timeline li .start {
    display: flex;
    align-items: center;

    .avatar.small {
        margin-right: 12px;
    }
    .tag {
        margin-right: 12px;
    }
}

.body {
    display: flex;
    margin: 60px 0px 0 4px;

    .right {
        flex: 1 0;

        p {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            margin-left: 10px;
        }
    }
}

.zerostate {
    position: absolute;
    left: 50%;
    top: 50%;
    translate: -50% -50%;
}

.top {
    display: flex;
    justify-content: space-between;

    h2 {
        font-weight: 500;
        font-size: 31px;
        line-height: 33px;
    }
}

* {
    font-family: Gotham;
}

h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 31px;
    line-height: 40px;
}

</style>
